import { Box, styled } from '@material-ui/core'
import { PageProps, navigate } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import MobileNavbar from '../../../components/navbar/mobile'
import Navbar from '../../../components/navbar'
import UniformCart from '../../../components/register/uniform-cart'
import UniformList from '../../../components/register/uniform-list'

const StyledContainer = styled(Box)`
  margin-top: 30px;
  padding: 1rem;
  padding-top: 0;
`

const RegisterRefereeOrderUniformPage = (props: PageProps) => {
  const cartType = 'referee'
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <StyledContainer
        sx={{
          marginTop: '30px',
        }}
      >
        <UniformCart
          onNext={() => navigate('/register/referee/review')}
          uniformType={cartType}
        />
        <UniformList
          onNext={() => navigate('/register/referee/review')}
          uniformType={cartType}
        />
      </StyledContainer>
    </>
  )
}

export default RegisterRefereeOrderUniformPage
